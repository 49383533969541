.tag-info-header {
	display: flex;
	margin-bottom: 20px;
}

.tag-info-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 20px;

	.tag-name {
		font-size: 20px;
		font-weight: bold;
	}

	.stats-row {
		text-align: center;
		margin: 10px 0px;
	}
}

.campaign-info-container {
	margin: 15px 10px 0px 10px;

	.campaign-tag-description {
		margin-top: 10px;
		font-size: 13px;
	}

	.campaign-tag-user-nickname {
		margin-left: 10px;
		color: black;
	}
}

.campaign-tag-cover {
	width: 100%;
}

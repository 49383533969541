.App {
	text-align: center;
}

.App-logo {
	height: 64px;
}

.App-container {
	margin-top: 100px;
}

.App-container-tabbed-header {
	margin-top: 140px;
}

.App-content {
	max-width: 550px;
	margin: auto;
}

@media screen and (max-width: 992px) {
	.App-container {
		margin-top: 80px;
	}

	.App-container .popular-posts-list {
		padding: 12px;
	}

	.App-container .posts-list .ant-list-header {
		padding: 12px;
	}
}

.App-link {
	color: #09d3ac;
}

.loading-container {
	position: absolute;
	bottom: 40px;
	width: 100%;
	text-align: center;
}

.post-thumbnail-container {
	position: relative;
}

.post-thumbnail {
	width: 100%;
	height: 128px;
	object-fit: cover;
}

.post-media-type-icon {
	position: absolute;
	left: 3px;
	width: 20px;
	bottom: 3px;
}

.popular-post-icon {
	position: absolute;
	left: 3px;
	width: 20px;
	top: 3px;
}

.editors-choice-icon {
	position: absolute;
	width: 32px;
	left: 3px;
}

.official-icon {
	width: 16px;
	margin-left: 5px;
}

.follow-button.following {
	color: black;
}
